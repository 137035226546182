import { useMediaQuery } from "@mantine/hooks";

export const useWindowSize = (windowSize) => {
  const sizes = {
    xs: "36em",
    sm: "48em",
    md: "62em",
    lg: "75em",
    xl: "88em",
  };

  return useMediaQuery(`(max-width: ${sizes[windowSize]})`);
};
