import {
  Card,
  Text,
  Title,
  LoadingOverlay,
  Box,
  Group,
  Radio,
  Paper,
  Flex,
  Overlay,
  Stepper,
  Grid,
  Notification,
  SimpleGrid,
  Modal,
  Button,
  Skeleton,
  Loader,
} from "@mantine/core";
import { FaCheckCircle, FaUserAlt, FaArrowAltCircleLeft } from "react-icons/fa";
import { AiFillWarning } from "react-icons/ai";
import { useStyles } from "../styles";
import { useWindowSize } from "../hooks/use-window-size.tsx";
import { navigateToUrl } from "single-spa";
import { usePathname } from "../hooks/use-pathname.tsx";
import { routesFilter } from "../resources/index.tsx";

export const CardComponent = ({
  title,
  subtitle,
  children,
  visible,
  disabled,
  styles,
  isAid = false,
  button = null,
  loadingText = "",
}) => {
  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      style={{ backgroundColor: "#00486F", height: "100%", ...styles }}
    >
      {loadingText.length > 0 ? (
        visible && (
          <Overlay
            radius="md"
            center
            color="rgba(0, 72, 111, 0.8)"
            backgroundOpacity={0.35}
            blur={4}
          >
            <Flex align="center" justify="center" gap={8}>
              <Text align="center" weight="bold" span fz={"xl"} color="white">
                {loadingText}
              </Text>
              <Loader variant="dots" color="white" />
            </Flex>
          </Overlay>
        )
      ) : (
        <LoadingOverlay
          visible={visible}
          overlayBlur={4}
          radius="md"
          overlayColor="rgba(0, 72, 111, 0.8)"
          loaderProps={{ color: "#cbd300" }}
          zIndex={200}
        />
      )}
      {disabled && (
        <Overlay
          radius="md"
          center
          color="rgba(0, 72, 111, 0.8)"
          backgroundOpacity={0.35}
          blur={4}
        >
          <Text align="center" weight="bold" span fz={"xl"} color="white">
            Completar "Información del {isAid ? "beneficiario" : "viajero"}"
            para continuar
          </Text>
        </Overlay>
      )}
      <Flex align="center" justify="space-between">
        <Title order={3}>
          <Text c="#cbd300" fw={500}>
            {title}
            <span style={{ color: "white", fontWeight: "bold" }}>
              {subtitle}
            </span>
          </Text>
        </Title>
        {button}
      </Flex>
      {children}
    </Card>
  );
};

export const CardComissioner = ({
  title,
  subtitle,
  children,
  visible,
  styles,
}) => {
  const { classes } = useStyles();
  return (
    <Card
      shadow="sm"
      padding={{ xs: "md", md: "xl" }}
      radius="md"
      withBorder
      style={{
        background: "white",
        border: "1px solid #cbd300",
        color: "#004236",
        marginTop: "24px",
        ...styles,
      }}
    >
      <LoadingOverlay
        visible={visible}
        overlayBlur={2}
        loaderProps={{ color: "#cbd300" }}
      />
      {children}
    </Card>
  );
};

export const CardAux = ({ title, subtitle, children, visible }) => {
  return (
    <Box pos="relative">
      <LoadingOverlay
        visible={visible}
        overlayBlur={2}
        loaderProps={{ color: "#cbd300" }}
      />
      <Card
        shadow="sm"
        padding="lg"
        radius="sm"
        withBorder
        style={{ backgroundColor: "#fff", margin: "10px 0" }}
      >
        <Title order={3}>
          <Text c="#cbd300" fw={500}>
            {title}
            <span style={{ color: "white", fontWeight: "bold" }}>
              {subtitle}
            </span>
          </Text>
        </Title>
        {children}
      </Card>
    </Box>
  );
};

export const PaperComponent = ({ icon, title, clicked, onClick, disabled }) => {
  const isClicked = clicked === "true";
  return (
    <Paper
      shadow="xs"
      sx={{
        backgroundColor: disabled
          ? "rgba(255,255,255,.1)"
          : isClicked
          ? "#CBD300"
          : "rgba(255,255,255,.1)",
        cursor: disabled ? "not-allowed" : "cursor",
        filter: disabled ? "brightness(60%)" : "none",
        padding: ".5rem",
        overflow: "hidden",
      }}
      h="100%"
      onClick={!disabled ? onClick : () => {}}
    >
      <FaCheckCircle
        size="1rem"
        style={{
          fill: disabled ? "transparent" : isClicked ? "white" : "transparent",
        }}
      />
      <Flex align="center" direction="column" wrap="wrap">
        {icon}
        <Text size="sm" c="white" ta="center">
          {title}
        </Text>
      </Flex>
    </Paper>
  );
};

export const SelectItem = ({
  value,
  label,
  description,
  formValue,
  selectType,
  ref,
  ...others
}) => {
  return selectType === "radio" ? (
    <Box ref={ref} {...others}>
      <Group noWrap>
        <Radio color="lime" readOnly checked={formValue === value} />
        {label}
      </Group>
    </Box>
  ) : (
    <Box ref={ref} {...others}>
      <Text>{label}</Text>
      <Text>{description}</Text>
    </Box>
  );
};

export const GlobalCard = ({ children, styles }) => {
  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      mt="lg"
      mb="lg"
      style={{
        border: "1px solid #cbd300",
        backgroundColor: "#fbfbfb",
        position: "unset",
        ...styles,
      }}
    >
      {children}
    </Card>
  );
};

export const StyledStepper = (props) => {
  return (
    <Stepper
      styles={(theme) => ({
        steps: {
          justifyContent: props.listLength > 5 ? "center" : "space-between",
          flexWrap: "wrap",
          rowGap: "12px",
          [theme.fn.smallerThan("sm")]: {
            gap: "12px",
          },
          [theme.fn.largerThan("xl")]: {
            justifyContent: "space-between",
          },
        },
        stepIcon: {
          width: "fit-content",
          paddingInline: "12px",
          borderRadius: "8px",
          backgroundColor: "#f9fce9",
        },
        stepCompletedIcon: {
          color: "#004236",
          position: "relative",
        },
        separator: {
          maxWidth: props.listLength > 5 ? "20px" : "",
          [theme.fn.smallerThan("sm")]: {
            display: "none",
          },
          [theme.fn.largerThan("xl")]: {
            maxWidth: "100%",
          },
        },
        root: {
          "@media print": {
            display: "none",
          },
        },
      })}
      color="#cbd300"
      size="md"
      {...props}
    ></Stepper>
  );
};

export const filteredRoutes = (dataFilter) => {
  return routesFilter.filter((route) => {
    return dataFilter
      ?.flatMap((item) => item.ServicioBpo)
      ?.some((item) => item.AliasServicio === route.alias);
  });
};

const getRouteIndex = (order, path) => {
  return order.findIndex((route) => route.path === path);
};

export const orderRoutes = (dataFilter) => {
  const isAid = dataFilter?.TipoProceso === 4;
  const allServicicesBpo = dataFilter?.ViajeTrayecto?.flatMap(
    ({ ServicioBpo }) => ServicioBpo
  );

  const routes = [
    {
      alias: isAid ? "AUXILIOS" : "VIAJES",
      path: isAid ? "/auxilios" : "/viajes",
      icon: <FaArrowAltCircleLeft />,
      name: isAid ? "Auxilios" : "Viajes",
    },
    ...filteredRoutes(dataFilter?.ViajeTrayecto),
  ];

  if (
    !!allServicicesBpo?.length ||
    (isAid && dataFilter?.ViajeTrayecto?.[0]?.auxilioMedico?.Acompanante)
  ) {
    routes.push({
      alias: "RVIAJERO",
      path: "/reserva-datos-viajero",
      icon: <FaUserAlt />,
      name: "Datos",
    });
  }

  routes.push({
    alias: "RDETALLES",
    path: "/reserva-detalles",
    icon: <FaCheckCircle />,
    name: "Reserva",
  });

  return routes;
};

export const navigateToNextRoute = (servicesData) => {
  const pathname = window.location.pathname;
  const order = orderRoutes(servicesData);

  return navigateToUrl(order[getRouteIndex(order, pathname) + 1].path);
};

export const StepperComponent = ({ servicesData }) => {
  const pathname = usePathname();
  const isXs = useWindowSize("xs");

  const orderRoutesFiltered = orderRoutes(servicesData);

  const handleStepClick = (index) => {
    navigateToUrl(orderRoutesFiltered[index].path);
  };

  return (
    <Skeleton visible={!servicesData?.TipoViajeAlias?.length}>
      <StyledStepper
        active={getRouteIndex(orderRoutesFiltered, pathname)}
        onStepClick={handleStepClick}
        allowNextStepsSelect={false}
        listLength={orderRoutesFiltered.length}
      >
        {orderRoutesFiltered?.map((item, index) => (
          <Stepper.Step
            key={index}
            icon={() =>
              !isXs ? (
                <Flex align="center" gap="12px">
                  {item.icon}
                  <Text>{item.name}</Text>
                </Flex>
              ) : (
                <>{item.icon}</>
              )
            }
            completedIcon={() =>
              !isXs ? (
                <Flex align="center" gap="12px">
                  {item.icon}
                  <Text>{item.name}</Text>
                </Flex>
              ) : (
                <>{item.icon}</>
              )
            }
          />
        ))}
      </StyledStepper>
    </Skeleton>
  );
};

export const ServiceDetailCard = ({
  children,
  totalLabel,
  totalCop,
  totalUsd,
  title = "",
  showTotal = false,
  totalDays,
  showTotalDays,
  hasInfo = false,
  gridStyles = {},
}) => {
  return (
    <>
      <Text size="lg" color="#004236" weight={700}>
        {title}
      </Text>
      <GlobalCard
        styles={{
          padding: 0,
        }}
      >
        <Grid columns={3} px={15} py={10} sx={gridStyles}>
          {children}
        </Grid>
        {!showTotal && (
          <Grid
            px={15}
            py={10}
            sx={{
              justifyContent: "space-around",
              "@media (min-width: 48em)": {
                justifyContent: "unset",
              },
            }}
          >
            <Grid.Col span={totalUsd === 0 ? 6 : "content"} sm={6}>
              <Text size="lg" weight={700} color="#004236">
                {totalLabel}
              </Text>
            </Grid.Col>
            <Grid.Col
              span={"content"}
              sm={3}
              sx={{ display: totalUsd === 0 && "none" }}
            >
              <Text
                ta="right"
                weight={700}
                color="#004236"
                sx={{
                  fontSize: "17px",
                  "@media (min-width: 48em)": {
                    fontSize: "1.125rem",
                  },
                }}
              >
                {totalUsd}
              </Text>
            </Grid.Col>
            <Grid.Col
              span={totalUsd === 0 ? 6 : "content"}
              sm={totalUsd === 0 ? 6 : 3}
            >
              <Text
                ta="right"
                weight={700}
                color="#004236"
                sx={{
                  fontSize: "17px",
                  "@media (min-width: 48em)": {
                    fontSize: "1.125rem",
                  },
                }}
              >
                {totalCop}
              </Text>
            </Grid.Col>
          </Grid>
        )}
        {showTotalDays && (
          <Grid sx={{ backgroundColor: "#F5F5F5" }} px={15} py={15}>
            <Grid.Col span="auto">
              <Text size="lg" ta="left" weight={700} color="#004236">
                Total días:
              </Text>
            </Grid.Col>
            <Grid.Col span="auto">
              <Text size="lg" ta="right" weight={700} color="#004236">
                {totalDays}
              </Text>
            </Grid.Col>
          </Grid>
        )}
      </GlobalCard>
    </>
  );
};

export const NotificationComponent = ({
  showNotification,
  requestErrorMessage,
  buttonMessage,
}) => {
  return (
    showNotification && (
      <>
        <Notification
          sx={{
            zIndex: "1000",
            width: "20%",
            position: "absolute",
            top: "50px",
            right: "50px",
          }}
          withCloseButton={false}
          color="red"
        >
          <SimpleGrid cols={1}>
            <AiFillWarning size="1.5rem" color="red" />
            {requestErrorMessage}
            <Button
              color="red"
              onClick={() => {
                location.reload();
              }}
            >
              {buttonMessage}
            </Button>
          </SimpleGrid>
        </Notification>
        <LoadingOverlay
          visible={true}
          overlayBlur={50}
          loaderProps={{ color: "#cbd300" }}
          sx={{ position: "fixed" }}
        />
      </>
    )
  );
};

export const ModalComponent = ({
  opened,
  onClose,
  title,
  children,
  ...props
}) => {
  const { classes } = useStyles();
  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={onClose}
      title={title}
      centered
      classNames={{
        header: classes.modalHeader,
        title: classes.modalTitle,
        close: classes.modalClose,
        body: classes.modalBody,
        inner: classes.modalInner,
        overlay: classes.modalOverlay,
      }}
      {...props}
    >
      {children}
    </Modal>
  );
};
