import { useState, useEffect } from "react";
import { usePathname } from "./use-pathname.tsx";
import { subscribeLib } from "../functions/index.ts";

export const useProtectRoute = () => {
  const pathname = usePathname();
  const [isProtected, setIsProtected] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    subscribeLib("userData", (msg, data) => {
      setUserData(data);
    });
  }, []);

  useEffect(() => {
    if (userData) {
      //@ts-ignore
      const routes = userData.menu;
      const listOfRoutes = routes.map((route) => route.links[0].link);
      setIsProtected(!listOfRoutes.includes(pathname));
    }
  }, [pathname, userData]);

  return { isProtected };
};
