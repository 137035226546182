export const images = {
  logoAve: require("./logoAve.png"),
  skyImageBackground: require("./sky.jpg"),
  clouds: require("./clouds.png"),
  logoGrupoEcopetrol: require("./logoGrupoEcopetrol.png"),
  logoGrupoEcopetrolCenit: require("./logoGrupoEcopetrolCenit.png"),
  avatar: require("./avatar.png"),
  bigAveLogo: require("./ave.svg"),
  favicon: require("./faviconAve.png"),
};
